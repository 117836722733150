import React from 'react'
import Container from 'gatsby-theme-amsterdam/src/components/Container'
import SEO from 'gatsby-theme-amsterdam/src/components/SEO'
import styled from '@emotion/styled'

const Title = styled.h1`
  font-weight: ${props => props.theme.fonts.boldWeight};
  line-height: 1.25;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto 1rem;
  font-size: 2rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 2.5rem;
  }
`

const Content = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  p {
    line-height: 1.5;
    margin: 0 0 1.75rem;
  }
  a {
    transition: 0.3s color;
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    @media (hover: none) {
      color: ${props => props.theme.colors.secondary} !important;
    }
  }
  code {
    font-family: ${props => props.theme.fonts.monospace};
    font-size: 0.9rem;
    padding: 0.25rem;
    background: ${props => props.theme.colors.code};
    color: ${props => props.theme.colors.text};
    border-radius: 0.3em;
  }
`

const ExamplePage = ({ data }) => {
  return (
    <Container>
      <SEO title="Example Page" description="This is just an example page" />
      <Title>Example Page</Title>
      <Content>
        <p>
          Brandon is a software developer, researcher, and dancer based in Oakland, California. He received a BA in Modern Culture and Media from Brown University and a master’s from New York University’s Interactive Telecommunications Program.
He was a 2021-2022 artist in residence at CultureHub. He worked as the lead API developer for the Rap Research Lab. He was a Yerba Buena Center for the Arts Fellow in 2016-17 and presented at the Playtest conference for emerging media at the Library of Congress in 2018. In 2007, he co-founded thePeople, an Oakland based collective focused on the curation and celebration of global, underground, Black music. His research is focused on the politics of presence and the body in relation to technology and draws heavily on a decade of experience training capoeira(the Afro-Brazilian “fighting dance”) and more recent study of popular and folkloric Cuba and Angola.

### Education
NEW UNIVERSITY
Tisch School of the Arts
Interactive Telecommunications

BROWN UNIVERSITY
Modern Culture and Media

### Residencies and Fellowships
CultureHub, Los Angeles - 2021/22

Yerba Buena Center for the Arts, San Francisco
December 15th, 2016 - February 18th, 2017
        </p>
      </Content>
    </Container>
  )
}

export default ExamplePage
